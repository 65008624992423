export default function AboutMe() {
  return (
    <section className="about--section">
      <div id="AboutMe" className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">About</p>
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
            I've dabbled in a whole spectrum of technologies, making me something of a Jack-of-all-trades in
            the coding world. My secret? I'm a firm believer that once you've got the principles down,
            you can jump into any tech stack with confidence.
          </p>
          <p className="hero--section-description">
            But there's more to me than just code. I hold a master's in sociology,
            which gives me a unique perspective on how people interact with technology.
            And when I'm not decoding the mysteries of software, I'm diving into the world of stoicism.
            Combining tech know-how with a dash
            of philosophy and social sciences, I like to think I bring a little extra flavor to the tech table.
          </p>
        </div>
      </div>
    </section>
  );
}
