import React, { useState, useEffect } from 'react';

export default function HeroSection() {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Delay setting the loaded state to trigger the animation
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 100); // 100ms delay
    return () => clearTimeout(timer);
  }, []);

  const fadeInStyle = loaded ? {
    opacity: 1,
    transition: 'opacity 1s ease'
  } : {
    opacity: 0
  };
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title typing-animation">Hey, I'm Vesna</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color ">Full Stack</span>{" "}
            <br />
            Developer
          </h1>
          <p className="hero--section-description">
            Fueled by endless coffee
            <br /> while figuring things out
          </p>
        </div>
        <button class="btn btn-primary">
          <a target="_blank" style={{ textDecoration: 'none', color: 'white' }} href="https://www.linkedin.com/in/vesna-bozic/">
            <span class="circle">
              <span class="icon arrow"></span>
            </span>
            <span class="button-text">Linkedin</span>
          </a>
        </button>


      </div>
      <div className="hero--section--img fade-in">
        <img src="./img/hero.svg" alt="Hero Section" />
      </div>
    </section>
  );
}
